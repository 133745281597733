import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import { ActionType, getType } from 'typesafe-actions';
import { imUserConfigActions } from '../actions/im-user-config.actions';
import { imUserConfigService } from '../services';
import { RequestState } from '../constants/request-state';
import { errorActions } from '../actions';
import { ImUserConfig } from '../types/user-config/UserConfig';
import { UserConfigType } from '../types/user-config/UserConfigType';
import { AppState } from '../types/state/AppState';
import { UserConfigState } from '../types/state/UserConfigState';
import { UserConfigFilter } from '../types/user-config/UserConfigFilter';
import { serializeArrangerPipelineFilters, serializeDealsPipelineFilters } from '../utils/filtering/serializers/amr-pipeline/serializeFilters';
import { issuanceMonitorFilterSelector } from '../selectors/amr-pipeline.selector';
import { PipelineType } from '../types/amr-pipeline/enums/PipelineType';
import { ArrangerPipelineFilters, DealsPipelineFilters, PipelineFilterState } from '../types/state/PipelineFilterState';
import { createFilterActions } from '../actions/filter.actions';
import { AlertOption } from '../types/amr-pipeline/enums/AlertOption';

function* watchGetUserConfig(action: ActionType<typeof imUserConfigActions.getUserConfig>) {
    try {
        const userConfig: ImUserConfig[] = yield call(imUserConfigService.getUserConfig);

        yield put(imUserConfigActions.getUserConfigResult(RequestState.success, userConfig));
    } catch (e) {
        yield put(imUserConfigActions.getUserConfigResult(RequestState.failure));
        yield put(errorActions.criticalError(e));
    } finally {
        action.payload.callback?.();
    }
}

function* watchGetUserFilterConfig(action: ActionType<typeof imUserConfigActions.getUserFilterConfig>) {
    try {
        const { configType } = action.payload;

        const userConfig: ImUserConfig[] = yield call(imUserConfigService.getUserConfig, configType);

        yield put(imUserConfigActions.getUserFilterConfigResult(RequestState.success, userConfig));
    } catch (e) {
        yield put(imUserConfigActions.getUserConfigResult(RequestState.failure));
        yield put(errorActions.criticalError(e));
    }
}

function* watchUpdateUserConfig(action: AnyAction) {
    try {
        const { userConfig } = action.payload;

        const calls = userConfig.map((config: ImUserConfig) => call(imUserConfigService.createOrUpdateUserConfig, config));

        yield all(calls);
        yield put(imUserConfigActions.updateUserConfigResult(RequestState.success, userConfig));
    } catch (e) {
        yield put(imUserConfigActions.updateUserConfigResult(RequestState.failure));
        yield put(errorActions.error(e));
    }
}

function* watchSaveTransactionAlerts(action: ActionType<typeof imUserConfigActions.saveTransactionAlerts>) {
    try {
        const { configs } = action.payload;

        const calls = configs.map((config) => {
            if (config.value?.alertOption === AlertOption.Never) {
                return imUserConfigService.deleteUserConfig(config.type, config.value.referenceName);
            }

            const imUserConfig = {
                type: config.type,
                value: JSON.stringify({
                    dealReferenceName: config.value.dealReferenceName,
                    transactionReferenceName: config.value.transactionReferenceName,
                    alertOption: config.value.alertOption,
                    referenceName: config.value.referenceName,
                }),
            } as ImUserConfig;


            return call(imUserConfigService.createOrUpdateUserConfig, imUserConfig);
        });

        yield all(calls);
        const userConfig: ImUserConfig[] = yield call(imUserConfigService.getUserConfig);
        yield put(imUserConfigActions.getUserConfigResult(RequestState.success, userConfig));
    }
    catch (e) {
        yield put(imUserConfigActions.updateUserConfigResult(RequestState.failure));
        yield put(errorActions.error(e));
    }
}

function* watchSaveCompanyAlerts(action: ActionType<typeof imUserConfigActions.saveCompanyAlerts>) {
    try {
        const { configs } = action.payload;

        const calls = configs.map(config => {
            if (config.value?.alertOption === AlertOption.Never) {
                return imUserConfigService.deleteUserConfig(config.type, config.value.referenceName);
            }

            const { companyReferenceName, alertOption, referenceName } = config.value;

            const imUserConfig = {
                type: config.type,
                value: JSON.stringify({
                    companyReferenceName,
                    alertOption,
                    referenceName,
                }),
            } as ImUserConfig;

            return call(imUserConfigService.createOrUpdateUserConfig, imUserConfig);
        });

        yield all(calls);
        const userConfig: ImUserConfig[] = yield call(imUserConfigService.getUserConfig);
        yield put(imUserConfigActions.getUserConfigResult(RequestState.success, userConfig));
    } catch (e) {
        yield put(imUserConfigActions.updateUserConfigResult(RequestState.failure));
        yield put(errorActions.error(e));
    }
}

function* watchGetDefaultUserColumnsConfig() {
    try {
        const defaultClassConfig: ImUserConfig = yield call(imUserConfigService.getDefaultUserConfig, UserConfigType.imColumnsClassTab);
        const defaultDealConfig: ImUserConfig = yield call(imUserConfigService.getDefaultUserConfig, UserConfigType.imColumnsDealTab);

        yield put(imUserConfigActions.getDefaultUserColumnsConfigResult(RequestState.success, [defaultClassConfig, defaultDealConfig]));
    } catch (e) {
        yield put(imUserConfigActions.getDefaultUserColumnsConfigResult(RequestState.failure));
        yield put(errorActions.criticalError(e));
    }
}

function* watchCreateUserFilter(action: ActionType<typeof imUserConfigActions.createUserFilter>) {
    try {
        const { pipelineType, userFilter } = action.payload;
        const filterSelector = issuanceMonitorFilterSelector(pipelineType);
        const imFilterState: PipelineFilterState<typeof pipelineType>  = yield select(filterSelector);
        const filterActions = createFilterActions(pipelineType);

        const { filter: filterState } = imFilterState;

        const serializedFilter =
            pipelineType === PipelineType.ArrangerPipeline
                ? serializeArrangerPipelineFilters(filterState as ArrangerPipelineFilters)
                : serializeDealsPipelineFilters(filterState as DealsPipelineFilters);

        const filterType = pipelineType === PipelineType.ArrangerPipeline ? UserConfigType.apFilter : UserConfigType.imFilter;

        const userConfigFilter = {
            ...userFilter,
            filter: serializedFilter,
        } as UserConfigFilter;

        const newFilterConfig = {
            type: filterType,
            value: JSON.stringify(userConfigFilter),
        } as ImUserConfig;

        const referenceName: string = yield call(imUserConfigService.createOrUpdateUserConfig, newFilterConfig, true);

        yield put(imUserConfigActions.getUserFilterConfig(filterType));
        yield put(filterActions.setFilterByReferenceName(referenceName, filterState));
    } catch (e) {
        yield put(imUserConfigActions.updateUserConfigResult(RequestState.failure));
        yield put(errorActions.error(e));
    }
}

function* watchUpdateUserFilter(action: AnyAction) {
    try {
        const { pipelineType, userFilter } = action.payload;
        const filterSelector = issuanceMonitorFilterSelector(pipelineType);
        const imFilterState: PipelineFilterState<typeof pipelineType> = yield select(filterSelector);
        const filterActions = createFilterActions(pipelineType);

        const { filter: filterState } = imFilterState;
        const filterType = PipelineType.ArrangerPipeline === pipelineType ? UserConfigType.apFilter : UserConfigType.imFilter;

        const serializedFilter =
            pipelineType === PipelineType.ArrangerPipeline
                ? serializeArrangerPipelineFilters(filterState as ArrangerPipelineFilters)
                : serializeDealsPipelineFilters(filterState as DealsPipelineFilters);

        const userConfigFilter = {
            ...userFilter,
            default: userFilter.default,
            alertOption: userFilter.alertOption,
            filter: serializedFilter,
        } as UserConfigFilter;

        const imUserConfig = {
            type: filterType,
            value: JSON.stringify(userConfigFilter),
        };

        const referenceName: string = yield call(imUserConfigService.createOrUpdateUserConfig, imUserConfig, true);
        yield put(imUserConfigActions.getUserFilterConfig(filterType));
        yield put(filterActions.setFilterByReferenceName(referenceName, filterState));
    } catch (e) {
        yield put(imUserConfigActions.updateUserConfigResult(RequestState.failure));
        yield put(errorActions.error(e));
    }
}

function* watchChangeUserParams(action: ActionType<typeof imUserConfigActions.setUserFilterParams>) {
    try {
        const { referenceName, filterType } = action.payload;
        const { filtersConfig }: UserConfigState = yield select((state: AppState) => state.imUserConfig);
        const filterConfig = filtersConfig[filterType].value.find(filter => filter.referenceName === referenceName);

        if (!filterConfig) {
            return;
        }

        const defaultFilter = {
            ...filterConfig,
            ...action.payload,
        };

        yield call(imUserConfigService.createOrUpdateUserConfig, {
            type: filterType,
            value: JSON.stringify(defaultFilter),
        }, true);

        yield put(imUserConfigActions.setUserFilterParamsResult(RequestState.success, filterType, defaultFilter));
    } catch (e) {
        yield put(imUserConfigActions.updateUserConfigResult(RequestState.failure));
        yield put(errorActions.error(e));
    }
}

function* watchDeleteUserFilter(action: ActionType<typeof imUserConfigActions.deleteUserFilter>) {
    const { referenceName, filterType } = action.payload;

    try {
        yield call(imUserConfigService.deleteUserFilter, referenceName, filterType);
        yield put(imUserConfigActions.deleteUserFilterResult(RequestState.success, filterType, referenceName));
    } catch (e) {
        yield put(imUserConfigActions.deleteUserFilterResult(RequestState.failure, filterType));
        yield put(errorActions.error(e));
    }
}

function* watchUpdateUserFilterEmailAlerts(action: AnyAction) {
    try {

        const {
            singleUpdateAlertOption,
            newTransactionAlertOption,
            statisticAlertOption,
            filterEmailAlerts
        } = action.payload;

        const imAlertsConfig = {
            type: UserConfigType.imAlert,
            value: JSON.stringify({alertOption: singleUpdateAlertOption}),
        } as ImUserConfig;

        const newTransactionConfig = {
            type: UserConfigType.newPublishedTransactionAlert,
            value: JSON.stringify({alertOption: newTransactionAlertOption}),
        } as ImUserConfig;

        const imStatisticsConfig = {
            type: UserConfigType.weeklyStatsEmail,
            value: JSON.stringify({emailOption: statisticAlertOption}),
        } as ImUserConfig;

        if (filterEmailAlerts.length) {
            yield call(imUserConfigService.updateFilterEmailAlertsConfig, filterEmailAlerts);
        }

        yield call(imUserConfigService.createOrUpdateUserConfig, imAlertsConfig);
        yield call(imUserConfigService.createOrUpdateUserConfig, newTransactionConfig);
        yield call(imUserConfigService.createOrUpdateUserConfig, imStatisticsConfig);
        const userConfig: ImUserConfig[] = yield call(imUserConfigService.getUserConfig);
        yield put(imUserConfigActions.getUserConfigResult(RequestState.success, userConfig));
    } catch (e) {
        yield put(imUserConfigActions.updateUserConfigResult(RequestState.failure));
        yield put(errorActions.error(e));
    }
}

export function* watchImUserConfig() {
    yield takeLatest(getType(imUserConfigActions.getUserConfig), watchGetUserConfig);
    yield takeLatest(getType(imUserConfigActions.getUserFilterConfig), watchGetUserFilterConfig);
    yield takeLatest(getType(imUserConfigActions.updateUserConfig), watchUpdateUserConfig);
    yield takeLatest(getType(imUserConfigActions.getDefaultUserColumnsConfig), watchGetDefaultUserColumnsConfig);

    yield takeLatest(getType(imUserConfigActions.createUserFilter), watchCreateUserFilter);
    yield takeLatest(getType(imUserConfigActions.updateUserFilter), watchUpdateUserFilter);
    yield takeLatest(getType(imUserConfigActions.setUserFilterParams), watchChangeUserParams);
    yield takeLatest(getType(imUserConfigActions.deleteUserFilter), watchDeleteUserFilter);
    yield takeLatest(getType(imUserConfigActions.updateUserFilterEmailAlerts), watchUpdateUserFilterEmailAlerts);

    yield takeLatest(getType(imUserConfigActions.saveTransactionAlerts), watchSaveTransactionAlerts);
    yield takeLatest(getType(imUserConfigActions.saveCompanyAlerts), watchSaveCompanyAlerts);
}

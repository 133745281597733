import { all, select, takeLatest } from 'redux-saga/effects';
import { ActionType, getType } from 'typesafe-actions';
import { dealsActions } from '../actions/deals.actions';
import { call, put } from 'redux-saga/effects';
import { Deal, DealShortInfo } from '../types/amr-pipeline/models/Deal';
import { errorActions } from '../actions/error.actions';
import { dealsService } from '../services/deals.service';
import { Transaction } from '../types/amr-pipeline/models/Transaction';
import { RequestState } from '../constants/request-state';
import { history } from '../history';
import { gridColumns, routes } from '../constants';
import { DealsTabType } from '../types/deals/DealsTabType';
import { flatten, sortBy } from 'lodash';
import { bwicService } from '../services';
import { Security } from '../types/security/Security';
import { SecurityBwicStatisticsSummary } from '../types/security/SecurityBwicStatisticsSummary';
import { gridActions } from '../actions';
import { AppState } from '../types/state/AppState';
import { DealDetails } from '../types/amr-pipeline/models/DealDetails';
import { OriginatingTransaction } from '../types/amr-pipeline/models/OriginatingTransaction';
import { Tranche } from '../types/amr-pipeline/models/Tranche';
import { GridDataItem } from '../types/state/GridState';
import { gridUtils, moneyUtils } from '../utils';
import { AmrDocument } from '../types/amr-pipeline/models/AmrDocument';
import { AmrClass } from '../types/amr-pipeline/models/AmrClass';
import { TransactionType } from '../types/amr-pipeline/enums/TransactionType';
import saveAs from 'file-saver';

function* watchGetDeals(action: ActionType<typeof dealsActions.getDeals>) {
    try {
        const { searchTerm } = action.payload;
        const deals: DealShortInfo[] = yield call(dealsService.getDealsNamesList, searchTerm || '', 'legalName', true);

        yield put(dealsActions.getDealsResult(deals));
    } catch (error) {
        yield errorActions.unexpectedError(error);
    }
}

function* loadDealDetails(dealReferenceName: string): Generator<unknown, DealDetails, any> {
    const [deal, transactions, documents]: [Deal, Transaction[], AmrDocument[]] = yield all([
        call(dealsService.getDealDetails, dealReferenceName),
        call(dealsService.getDealTransactions, dealReferenceName),
        call(dealsService.getDealDocuments, dealReferenceName),
    ]);

    return {
        ...deal,
        transactions: sortBy(transactions, 'closingDate').reverse(),
        documents,
    };
}

function* watchGetDealDetails(action: ActionType<typeof dealsActions.getDealDetails>) {
    try {
        const { referenceName } = action.payload;

        const dealDetails: DealDetails = yield loadDealDetails(referenceName);

        yield put(dealsActions.getDealDetailsResult(referenceName, dealDetails));
    } catch (error) {
        yield errorActions.unexpectedError(error);
    }
}

export function* watchDealUpdate(action: ActionType<typeof dealsActions.updateDeal>) {
    try {
        const { referenceName, formData } = action.payload;

        const dealReferenceName: string = yield call(dealsService.updateDeal, referenceName, formData);
        yield put(dealsActions.updateDealResult(RequestState.success));

        yield call(history.push, routes.dealsUrl(dealReferenceName, DealsTabType.DealDetail));
        yield put(dealsActions.getDealDetails(dealReferenceName));
    } catch (error) {
        yield errorActions.unexpectedError(error);
    }
}

function* watchGetClassSecondaryInfo(action: ActionType<typeof dealsActions.getClassSecondaryInfo>) {
    try {
        const { selectedClass } = action.payload;
        const {
            ticker144A,
            cusiP144A,
            isiN144A,
            tickerRegS,
            cusipRegS,
            isinRegS,
            tickerAccdInvCertif,
            cusipAccdInvCertif,
            isinAccdInvCertif,
        } = selectedClass;

        const ticker = ticker144A || tickerRegS || tickerAccdInvCertif;
        const cusip = cusiP144A || cusipRegS || cusipAccdInvCertif;
        const isin = isiN144A || isinRegS || isinAccdInvCertif;

        const identifier =
            ticker144A ||
            cusiP144A ||
            isiN144A ||
            tickerRegS ||
            cusipRegS ||
            isinRegS ||
            tickerAccdInvCertif ||
            cusipAccdInvCertif ||
            isinAccdInvCertif;

        const securities: Security[] = yield call(bwicService.searchSecurities, identifier);

        const security = securities.find(
            s =>
                (ticker ? ticker === s.ticker : true) &&
                (cusip ? cusip === s.cusip : true) &&
                (isin ? isin === s.isin : true),
        );

        let statistics: SecurityBwicStatisticsSummary | undefined;

        if (security) {
            statistics = yield call(bwicService.getSecurityBwicStatistics, security.id);
        }

        yield put(dealsActions.getClassSecondaryInfoResult(security, statistics));
    } catch (error) {
        yield errorActions.unexpectedError(error);
    }
}

function* watchDeleteDeal(action: ActionType<typeof dealsActions.deleteDealRequest>) {
    try {
        const { referenceName } = action.payload;
        const deals: Deal[] = yield select((s: AppState) => s.deals.data);

        const dealIndex = deals.findIndex(d => d.referenceName === referenceName);

        const previousDeal = deals[dealIndex - 1];
        const nextDeal = deals[dealIndex + 1];

        yield call(dealsService.deleteDeal, referenceName);
        yield put(dealsActions.deleteDealResult(RequestState.success, referenceName));

        if (previousDeal || nextDeal) {
            const refName = previousDeal?.referenceName || nextDeal?.referenceName;

            yield call(history.push, routes.dealsUrl(refName, DealsTabType.Overview));
        } else {
            yield call(history.push, routes.dealsCommon);
        }
    } catch (error) {
        yield errorActions.unexpectedError(error);
    }
}

function* watchAllClassesEditInit(action: ActionType<typeof dealsActions.allClassesEditInit>) {
    const { deal } = action.payload;

    yield put(gridActions.reset());

    const transactions: OriginatingTransaction[] = yield call(dealsService.getDealTransactions, deal.referenceName, {
        transactionTypes: [TransactionType.newIssue, TransactionType.refi, TransactionType.reset],
    });

    const dealsTransactionReferenceAndClass = {
        ...gridColumns.dealsTransactionReferenceAndClass,
        selectSourceItemsCallback: () =>
            flatten(
                transactions.map(transaction =>
                    transaction.classes.map(transactionClass => ({
                        key: `${transaction.referenceName};${transactionClass.referenceName}`,
                        title: `${transaction.referenceName}, ${transactionClass.referenceName}`,
                    })),
                ),
            ),
    };

    const allClassesColumns = gridColumns.dealsAllClasses().map(column => {
        switch (column.name) {
            case gridColumns.dealsFloaterIndex.name:
            case gridColumns.dealsMargin.name:
                return { ...column, updateDependencyColumnsCallback: dealsActions.allClassesUpdateCoupon };
            case gridColumns.dealsStatus.name:
                return { ...column, updateDependencyColumnsCallback: dealsActions.allClassesUpdateStatus };
            default:
                return column;
        }
    });

    const columns = [...allClassesColumns, dealsTransactionReferenceAndClass];

    const classes = deal.classes.map(tranche => ({
        ...tranche,
        currencyCode: deal.currencyCode,
        linkedTransactionReferenceAndClass:
            tranche.transactionReferenceName && tranche.linkedOtcReferenceName
                ? `${tranche.transactionReferenceName};${tranche.linkedOtcReferenceName}`
                : null,
    }));

    yield put(dealsActions.allClassesEdit(classes));
    yield put(gridActions.init(classes, columns, 100));
}

function* watchAllClassesUpdate(action: ActionType<typeof dealsActions.allClassesUpdate>) {
    const { dealReferenceName } = action.payload;

    try {
        yield put(gridActions.validate());

        const dataItems: GridDataItem<Tranche & { linkedTransactionReferenceAndClass: string }>[] = yield select(
            (state: AppState) => state.grid.dataItems,
        );

        const nonDraftItems = dataItems.filter(item => !item.draft);

        if (nonDraftItems.some(x => x.errors?.length)) {
            return;
        }

        const classes = gridUtils
            .sanitizeGridItems(nonDraftItems)
            .map(({ linkedTransactionReferenceAndClass, ...tranche }) => {
                const [transactionReferenceName, linkedOtcReferenceName] = (
                    linkedTransactionReferenceAndClass || ''
                ).split(';');

                return {
                    ...tranche,
                    transactionReferenceName,
                    linkedOtcReferenceName,
                };
            });

        yield call(dealsService.updateClasses, dealReferenceName, classes);
        const dealDetails: DealDetails = yield loadDealDetails(dealReferenceName);

        yield put(dealsActions.getDealDetailsResult(dealReferenceName, dealDetails));

        yield call(history.push, routes.dealsAllClasesUrl(dealReferenceName));
    } catch (error) {
        yield errorActions.unexpectedError(error);
    } finally {
        yield put(dealsActions.allClassesUpdateResponse());
    }
}

function* watchSaveExcludedDealers(action: ActionType<typeof dealsActions.saveExcludedDealersRequest>) {
    try {
        const { dealReferenceName, transactionReferenceName, classReferenceName, excludedDealersReferenceNames } =
            action.payload;

        const editData = {
            editData: excludedDealersReferenceNames.map(dealerReferenceName => ({
                trancheReferenceName: classReferenceName,
                brokerDealerReferenceName: dealerReferenceName,
                reasons: [],
            })),
        };

        yield call(
            dealsService.saveExcludedDealers,
            dealReferenceName,
            transactionReferenceName,
            classReferenceName,
            editData,
        );

        yield put(dealsActions.saveExcludedDealersResponse());
    } catch (error) {
        yield errorActions.unexpectedError(error);
    }
}

function* watchSaveSettlementInstructions(action: ActionType<typeof dealsActions.saveSettlementInstructionsRequest>) {
    try {
        const { dealReferenceName, classReferenceName, settlementInstructions } = action.payload;

        yield call(
            dealsService.saveSettlementInstructions,
            dealReferenceName,
            classReferenceName,
            settlementInstructions,
        );

        yield put(dealsActions.saveSettlementInstructionsResponse());
    } catch (error) {
        yield errorActions.unexpectedError(error);
    }
}

function* watchAmrInfoClassesEdit(action: ActionType<typeof dealsActions.amrInfoClassesEdit>) {
    const { classes } = action.payload;

    yield put(gridActions.reset());

    const columns = gridColumns.dealsAmrClasses();

    const minAmountColumn = {
        ...gridColumns.minAmount,
        validate: (value: number, dataItem: GridDataItem<AmrClass>) => {
            const minimumDenomination = Number(dataItem.minimumDenomination);

            return value < minimumDenomination * 2
                ? `Minimum amount should be greater or equal to ${moneyUtils.money(minimumDenomination * 2)} [2 x Min. Denomination]`
                : '';
        },
    };

    yield put(
        gridActions.init(
            classes,
            columns.map(c => (c.name === minAmountColumn.name ? minAmountColumn : c)),
            classes.length,
        ),
    );
}

export function* watchDealDocumentsUpdate(action: ActionType<typeof dealsActions.updateDealDocuments>) {
    try {
        const { referenceName, documents } = action.payload;
        yield call(dealsService.updateDealDocuments, referenceName, documents);
        yield put(dealsActions.updateDealDocumentsResult(RequestState.success));
        yield call(history.push, routes.dealsUrl(referenceName, DealsTabType.Documents));
        yield put(dealsActions.getDealDetails(referenceName));
    } catch (error) {
        yield errorActions.unexpectedError(error);
    }
}

function* watchAmrInfoSave(action: ActionType<typeof dealsActions.amrInfoSave>) {
    try {
        const { dealReferenceName, transactionReferenceName, amrInfo } = action.payload;

        yield put(gridActions.validate());

        const dataItems: GridDataItem<AmrClass>[] = yield select((state: AppState) => state.grid.dataItems);
        const nonDraftItems = dataItems.filter(item => !item.draft);

        if (nonDraftItems.some(x => x.errors?.length)) {
            return;
        }

        const classes = gridUtils.sanitizeGridItems(nonDraftItems).map(c => ({...c, redemptionPrice: c.redemptionPriceInPercent / 100 }));

        yield call(dealsService.saveAmrInfo, dealReferenceName, transactionReferenceName, { ...amrInfo, classes });

        yield call(history.push, routes.dealAmrInfoUrl(dealReferenceName, transactionReferenceName));

        const dealDetails: DealDetails = yield loadDealDetails(dealReferenceName);
        yield put(dealsActions.getDealDetailsResult(dealReferenceName, dealDetails));

    } catch (error) {
        yield errorActions.unexpectedError(error);
    } finally {
        yield put(dealsActions.saveAmrInfoResponse());
    }
}

function* watchDeleteAmrTransaction(action: ActionType<typeof dealsActions.deleteAmrTransaction>) {
    try {
        const { dealReferenceName, transactionReferenceName } = action.payload;
        yield call(dealsService.deleteAmrInfo, dealReferenceName, transactionReferenceName);
        yield put(dealsActions.getDealDetails(dealReferenceName));
        yield call(history.push, routes.dealsUrl(dealReferenceName, DealsTabType.AmrInfo));
    } catch (error) {
        yield errorActions.unexpectedError(error);
    }
}

function* watchExportSettlementInstructions(action: ActionType<typeof dealsActions.exportSettlementInstructions>) {
    try {
        const { dealReferenceName, dealLegalName, transactionReferenceName, classReferenceName, classLegalName } = action.payload;
        const file: { name: string, blob: Blob } =  yield call(
            dealsService.exportSettlementInstructions,
            dealReferenceName,
            transactionReferenceName,
            classReferenceName,
        );
        saveAs(file.blob, `${dealLegalName} ${classLegalName} Settlement Instructions.pdf`);
    } catch (error) {
        yield errorActions.unexpectedError(error);
    }
}

export function* watchDeals() {
    yield takeLatest(getType(dealsActions.getDeals), watchGetDeals);
    yield takeLatest(getType(dealsActions.getDealDetails), watchGetDealDetails);
    yield takeLatest(getType(dealsActions.updateDeal), watchDealUpdate);
    yield takeLatest(getType(dealsActions.getClassSecondaryInfo), watchGetClassSecondaryInfo);
    yield takeLatest(getType(dealsActions.deleteDealRequest), watchDeleteDeal);
    yield takeLatest(getType(dealsActions.allClassesEditInit), watchAllClassesEditInit);
    yield takeLatest(getType(dealsActions.updateDealDocuments), watchDealDocumentsUpdate);
    yield takeLatest(getType(dealsActions.allClassesUpdate), watchAllClassesUpdate);
    yield takeLatest(getType(dealsActions.saveExcludedDealersRequest), watchSaveExcludedDealers);
    yield takeLatest(getType(dealsActions.saveSettlementInstructionsRequest), watchSaveSettlementInstructions);
    yield takeLatest(getType(dealsActions.exportSettlementInstructions), watchExportSettlementInstructions);
    yield takeLatest(getType(dealsActions.amrInfoClassesEdit), watchAmrInfoClassesEdit);
    yield takeLatest(getType(dealsActions.amrInfoSave), watchAmrInfoSave);
    yield takeLatest(getType(dealsActions.deleteAmrTransaction), watchDeleteAmrTransaction);
}
